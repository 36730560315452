
import React from 'react'
import './Bios.css'
import localizedStrings from './Strings'
import mesvg from './me_svg.svg'

function Bios() {
    return (
        <div className=''>
            <section className='bios'>
                <p className='biosOne'>
                    <div className="app-me">
                        <img
                            className="me"
                            src={mesvg}
                            alt="First slide"
                        />
                    </div>
                    {localizedStrings.bioOne['en/US']}
                </p>
                <p className='biosTwo'>
                    {localizedStrings.bioTwo['en/US']}
                </p>
            </section>
        </div>

    )
}

export default Bios