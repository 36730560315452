import React from 'react'
import './TopNav.css'

function TopNav(props) {
  return (
    <nav className='TopNav'>
      {props.navItems.map(item => (
        <a href={item.url} className='TopNav-link'>
          {item.linkTitle}
        </a>
      ))}
    </nav>
  )
}

export default TopNav