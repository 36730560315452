
import React, { useState, useEffect } from 'react'
import './App.css'
import 'bootstrap/dist/css/bootstrap.min.css';

// * IMPORTED COMPONENTS
import TopNav from './components/TopNav'
import Banner from './components/Banner'
import Projects from './components/Projects'
import Bios from './components/Bios'
import logo from './components/BBLogo.png'

// * FONT AWESOME * //
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
//import { faLink } from '@fortawesome/free-solid-svg-icons'
import { faTwitter, faLinkedin, faGithub } from "@fortawesome/free-brands-svg-icons";

// * VARIABLES
const navItems = [
  { id: 1, linkTitle: <FontAwesomeIcon icon={faLinkedin} />, url: 'https://www.linkedin.com/feed/' },
  { id: 2, linkTitle: <FontAwesomeIcon icon={faGithub} />, url: 'https://github.com/brad0447' },
  { id: 3, linkTitle: <FontAwesomeIcon icon={faTwitter} />, url: 'https://twitter.com/bbradley_dev' }
]



function App() {

  useEffect(() => {
    document.title = "BB Dev"
  }, [])

  return (
    <div className="App">
      <Banner />
      <h3 className="center">About Me</h3>
      <Bios />
      <h3 className="center">Projects</h3>
      <Projects />
      <footer className='App-header'>
        <div className='App-logo'>
          <img
            className="logo"
            src={logo}
            alt="First slide"
          />
        </div>
        <TopNav navItems={navItems} />
      </footer>
    </div>

  );
}

export default App;
