import React from 'react'
import './Banner.css'

function Banner() {
    return (
        <div className='Hero'>
            <div className='Title-background'>
                <p className='Title'>Ben Bradley</p>
                <p className='Sub-title'>Developer of Mobile and Web Application</p>
            </div>
        </div>
    )
}

export default Banner