//import React, { useState } from 'react'
import './Projects.css'
import Carousel from 'react-bootstrap/Carousel';
// import image from './placeholder.jpeg'
// import image2 from './videogame.jpeg'
// import image3 from './code.png'
import skillsGuildPoster from './SkillsGuildPoster.png'


function Projects() {
    // const [index, setIndex] = useState(0);

    // const handleSelect = (selectedIndex, e) => {
    //     setIndex(selectedIndex);
    // };

    return (
        <div className='Projects-section'>
            <Carousel className="Carousel">
                <Carousel.Item>
                    <div className="image-holder">
                        <img
                            className="c-image"
                            src={skillsGuildPoster}
                            alt="First slide"
                        />
                    </div>
                    <Carousel.Caption className='Overlay-text-section'>
                        <div className='Overlay-text-section'>
                            {/* <h3 className>First slide label</h3>
                            <p>Nulla vitae elit libero, a pharetra augue mollis interdum.</p> */}
                        </div>
                    </Carousel.Caption>
                </Carousel.Item>
                <Carousel.Item>
                    <div className="image-holder">
                        <img
                            className="c-image"
                            src={skillsGuildPoster}
                            alt="Second slide"
                        />
                    </div>
                    <Carousel.Caption>
                        {/* <h3>Second slide label</h3>
                        <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit.</p> */}
                    </Carousel.Caption>
                </Carousel.Item>
                <Carousel.Item>
                    <div className="image-holder">
                        <img
                            className="c-image"
                            src={skillsGuildPoster}
                            alt="Third slide"
                        />
                    </div>
                    <Carousel.Caption>
                        {/* <h3>Third slide label</h3>
                        <p>Praesent commodo cursus magna, vel scelerisque nisl consectetur.</p> */}
                    </Carousel.Caption>
                </Carousel.Item>
            </Carousel>
        </div>
    )
}

export default Projects