

const localizedStrings = {
    template: {
        'en/US': ' ',
        'fr/FR': ' '
    },

    confirmMessage: {
        'en/US': 'Are you sure?',
        'fr/FR': 'Est-ce que vous êtes certain?'
    },

    bioOne: {
        'en/US': 'Welcome to my portfolio website! While continuing to educate myself and progress my career in development, I will be adding achievements, projects, and whatever else may be relevant to my world of mobile and web development.',
        'fr/FR': ''
    },

    bioTwo: {
        'en/US': 'I began my journey in development at pivotal time in my life. I was in concrete restoration and thought about my future daily while working. I had a background in technology as I was an employee at an Apple Store and loved working with the latest and in my opionion greatest of accessible technology. While working at the store I would watch from afar while workshops were delivered to an audience of young minds, introducing them to the world of coding and development. I was envious but inspired that these individuals had the chance to learn something so cool, so young. Anyway, while chipping away at concrete day to day I was thinking of these workshops and it came to me, I clearly have an interest and a passion for technology, why not learn to speak to said technology through coding. So from there I registered myself with Algonquin College, specifacally the Mobile Application Design and Development program. One of the best decisions I have made. I now currently work in my field and could not be happier.',
        'fr/FR': ' '
    },

    myStatus: {
        'en/US': 'I am currently diving deep into Swift and really enjoying every part of the new SwiftUI.',
        'fr/FR': ' '
    }
}


export default localizedStrings